/*
 *  Copyright (C) 2019 Reuben Trotter
 */

export interface NewsfeedStoryInterface {
    hash: string;
    title: string;
    website: string;
    published: string;
    score: number;
    language: string;
    countries: Array<string>;
    countries_iso2: Array<string>;
    countries_iso3: Array<string>;
    topics: Array<string>;
    label: string;
    label_type: string;
}

export class NewsfeedStory {
    hash: string;
    title: string;
    website: string;
    date: Date;
    score: number;
    language: string;
    countries: Array<string>;
    countriesIso2: Array<{country: string, countryIso3: string, flag: string}>;
    countriesIso3: Array<string>;
    topics: Array<string>;
    label: string;
    labelType: string;

    constructor(data: NewsfeedStoryInterface) {
        this.hash = data.hash;
        this.title = data.title;
        this.website = data.website;
        this.date = new Date(data.published);
        this.score = data.score;
        this.language = (data.language ? data.language.charAt(0).toUpperCase() + data.language.substring(1) : 'English');
        this.countries = data.countries.map(d => d ||'Unknown') || ['Unknown'];
        this.countriesIso2 = [];
        for (let index = 0; index < data.countries_iso3.length; index++) {
            const d = data.countries_iso3[index];
            let d2 = JSON.parse(JSON.stringify(data.countries_iso3[index]));
            if (index < data.countries_iso3.length -1) {
                d2 = d2.concat(', ');
            }
            this.countriesIso2.push({
                country: (this.countries[index] || 'Unknown'),
                countryIso3: (d2 || 'Unknown'),
                flag: (d || '' ).toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
            });
        }
        for (let index = 0; index < data.countries.length; index++) {
            const element = data.countries[index];

            if (element === 'United States of America') {
                data.countries_iso3.push('USA');
            }
            if (element === 'Russian Federation') {
                data.countries_iso3.push('RUS');
            }
            if (element === 'United Kingdom of Great Britain and Northern Ireland') {
                data.countries_iso3.push('GBR');
            }

        }
        this.countriesIso3 = data.countries_iso3.map(d => d || 'Unknown') || ['Unknown'];
        if (data.topics === null) {
            this.topics = ['Unknown']
        } else {
        this.topics = data.topics.map(d => d.charAt(0).toUpperCase() + d.slice(1) || 'Unknown') || ['Unknown'];
        }
        this.label = `${parseInt(data.label)}`;
        this.labelType = data.label_type;
    }
}
